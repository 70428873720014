import { Plugin, type Chart } from "chart.js";
// import { type Plugin } from "chart.js";

const arrowSVG = `
    <svg width="19" height="20" viewBox="0 0 19 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 8.875L9.25 2M9.25 2L16.5 8.875M9.25 2V35" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
`;

const drawArrow = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  inverted: boolean,
) => {
  const image = new Image();
  image.src = `data:image/svg+xml;base64,${window.btoa(arrowSVG)}`;
  image.onload = () => {
    ctx.save();
    if (inverted) {
      ctx.translate(x, y);
      ctx.rotate(Math.PI);
      ctx.translate(-x, -y);
    }
    ctx.drawImage(image, x, y);
    ctx.restore();
  };
};

// @ts-expect-error Not Properly Typed
function drawRoundedCornerPolygon(ctx, points, radius) {
  // @ts-expect-error Not Properly Typed
  const distance = (p1, p2) =>
    Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2);
  // @ts-expect-error Not Properly Typed
  const lerp = (a, b, x) => a + (b - a) * x;
  // @ts-expect-error Not Properly Typed
  const lerp2D = (p1, p2, t) => ({
    x: lerp(p1.x, p2.x, t),
    y: lerp(p1.y, p2.y, t),
  });

  const numPoints = points.length;

  const corners = [];
  for (let i = 0; i < numPoints; i++) {
    const lastPoint = points[i];
    const thisPoint = points[(i + 1) % numPoints];
    const nextPoint = points[(i + 2) % numPoints];

    const lastEdgeLength = distance(lastPoint, thisPoint);
    const lastOffsetDistance = Math.min(lastEdgeLength / 2, radius);
    const start = lerp2D(
      thisPoint,
      lastPoint,
      lastOffsetDistance / lastEdgeLength,
    );

    const nextEdgeLength = distance(nextPoint, thisPoint);
    const nextOffsetDistance = Math.min(nextEdgeLength / 2, radius);
    const end = lerp2D(
      thisPoint,
      nextPoint,
      nextOffsetDistance / nextEdgeLength,
    );

    corners.push([start, thisPoint, end]);
  }

  // ctx.moveTo(corners[0][0].x, corners[0][0].y);
  for (const [start, ctrl, end] of corners) {
    ctx.lineTo(start.x, start.y);
    ctx.quadraticCurveTo(ctrl.x, ctrl.y, end.x, end.y);
  }
}

export const drawFatArrowBar: Plugin = {
  id: "drawFatArrowBar",
  beforeDatasetDraw(chart: Chart) {
    const { ctx } = chart;
    const datasets = chart.data.datasets;
    const barColors = {
      positive: "#33798C",
      neutral: "#AAA246",
      negative: "#B66E3F",
    };

    for (const [datasetIndex, data] of datasets.entries()) {
      const metadata = chart.getDatasetMeta(datasetIndex).data;
      for (const [metaIndex, meta] of metadata.entries()) {
        const barPoints = data.data[metaIndex] as any;
        const direction =
          barPoints[0] !== barPoints[1]
            ? barPoints[0] < barPoints[1]
              ? "positive"
              : "negative"
            : "neutral";
        // @ts-expect-error Not Properly Typed
        const barWidth = meta.width;
        // @ts-expect-error Not Properly Typed
        const barHeight = meta.height;
        const arrowLength = 20;
        const xPos = meta.x;
        const yPos = meta.y;
        const capWidth = barWidth; // The triangle on top or bottom
        const capHeight = barWidth / 3; // The triangle on top or bottom
        const barTL = { x: xPos - barWidth / 2, y: yPos };
        const barTR = { x: xPos + barWidth / 2, y: yPos };
        const barBL = { x: xPos - barWidth / 2, y: yPos + barHeight };
        const barBR = { x: xPos + barWidth / 2, y: yPos + barHeight };

        ctx.save();
        ctx.lineWidth = 1;
        ctx.strokeStyle = "rgba(0, 0, 0, 1)";
        ctx.fillStyle = barColors[direction];
        ctx.beginPath();

        if (direction === "positive") {
          drawRoundedCornerPolygon(
            ctx,
            [
              { x: barTL.x, y: barTL.y + capHeight },
              { x: barTL.x + capWidth / 2, y: yPos },
              { x: barTR.x, y: barTR.y + capHeight },
              { x: barBR.x, y: barBR.y },
              { x: barBL.x, y: barBL.y },
            ],
            3,
          );
        }

        if (direction === "negative") {
          drawRoundedCornerPolygon(
            ctx,
            [
              { x: barTL.x, y: barTL.y },
              { x: barTR.x, y: barTR.y },
              { x: barBR.x, y: barBR.y - capHeight },
              { x: barBR.x - capWidth / 2, y: barBR.y },
              { x: barBL.x, y: barBL.y - capHeight },
            ],
            3,
          );
        }

        if (direction === "neutral") {
          drawRoundedCornerPolygon(
            ctx,
            [
              { x: barTL.x, y: barTL.y - 4 },
              { x: barTR.x, y: barTR.y - 4 },
              { x: barBR.x, y: barBR.y + 4 },
              { x: barBL.x, y: barBL.y + 4 },
            ],
            6,
          );
        }

        ctx.closePath();
        ctx.fill();

        if (direction !== "neutral" && barHeight > arrowLength * 2) {
          const inverted = direction === "negative";
          drawArrow(
            ctx,
            inverted ? xPos + 9 : xPos - 9,
            inverted
              ? yPos + barHeight / 2 + arrowLength / 2
              : yPos + barHeight / 2 - arrowLength / 2,
            direction === "negative",
          );
        }
      }
    }
  },
};
