import type { IMilestoneReports } from "@/modules/charts-dashboard/services/data/milestone-reports/milestone.interface";

export const defaultLevelsChartData = {
  type: "bar",
  data: {
    labels: [
      "2024 Cohort",
      "Pixelmatter VIL",
      "Uniplaces VIL",
      "Naborforce VIL",
      "Roundtrip VIL",
      "Humanitru VIL",
    ],
    datasets: [
      {
        backgroundColor: "transparent",
        barPercentage: 1,
        barThickness: 32,
        label: "Leveled Up",
        maxBarThickness: 32,
        minBarLength: 0,
        data: [
          [1, 5],
          [2, 7],
          [3, 3],
          [3.5, 4.5],
          [5, 2],
          [3, 1],
        ],
      },
    ],
  },
};

export const defaultMilestonesChartData = {
  chartLimits: {
    min: -10,
    max: 10,
  },
  chartData: {
    type: "bar",
    data: {
      labels: [
        "2024 Cohort",
        "Pixelmatter VIL",
        "Uniplaces VIL",
        "Naborforce VIL",
        "Roundtrip VIL",
        "Humanitru VIL",
      ],
      datasets: [
        {
          label: "Levels Growth",
          barPercentage: 0.5,
          categoryPercentage: 1,
          barThickness: 70,
          maxBarThickness: 70,
          backgroundColor: "#33798C",
          minBarLength: 0,
          data: [
            [0, -2],
            [0, -2],
            [0, 2],
            [0, -2],
            [0, 3],
            [0, 4],
          ],
        },
        {
          label: "Milestones growth",
          barPercentage: 0.5,
          categoryPercentage: 1,
          barThickness: 70,
          backgroundColor: "#C0517F",
          maxBarThickness: 70,
          minBarLength: 0,
          data: [
            [0, 3],
            [0, 6],
            [0, -3],
            [0, -3],
            [0, 4],
            [0, 8],
          ],
        },
      ],
    },
  },
};

export const createLevelsChartData = (data: IMilestoneReports) => {
  try {
    const labels = [];
    const dataPoints = [];

    for (const company of data.companies) {
      labels.push(`${company.company_name.substring(0, 7)}... VIL`);
      dataPoints.push([
        company.last_assessment?.vil,
        company.first_assessment?.vil === company.last_assessment?.vil
          ? company.last_assessment?.vil + 2
          : company.last_assessment?.vil,
      ]);
    }

    return {
      type: "bar",
      data: {
        labels,
        datasets: [
          {
            barPercentage: 1,
            barThickness: 32,
            maxBarThickness: 32,
            minBarLength: 0,
            label: "Leveled Up",
            backgroundColor: "transparent",
            data: dataPoints,
          },
        ],
      },
    };
  } catch (err) {
    console.log(err);
  }
};

export const createMilestonesChartData = (data: IMilestoneReports) => {
  try {
    const labels = [];
    const vilDataPoints = [];
    const milestoneDataPoints = [];

    let chartPoints = [];

    for (const company of data.companies) {
      labels.push(`${company.company_name.substring(0, 7)}... VIL`);
      vilDataPoints.push([0, company.vil_level_progress]);
      milestoneDataPoints.push([0, company.milestone_progress]);

      chartPoints.push(company.vil_level_progress);
      chartPoints.push(company.milestone_progress);
    }

    chartPoints = chartPoints
      .map((n) => (n < 0 ? n * -1 : n))
      .sort((a, b) => b - a);

    return {
      chartLimits: {
        min: chartPoints[0] * -1,
        max: chartPoints[0],
      },
      chartData: {
        type: "bar",
        data: {
          labels,
          datasets: [
            {
              label: "Levels Growth",
              barPercentage: 1,
              barThickness: 70,
              maxBarThickness: 70,
              backgroundColor: "#33798C",
              minBarLength: 0,
              data: vilDataPoints,
            },
            {
              label: "Milestones growth",
              barPercentage: 1,
              barThickness: 70,
              backgroundColor: "#C0517F",
              maxBarThickness: 70,
              minBarLength: 0,
              data: milestoneDataPoints,
            },
          ],
        },
      },
    };
  } catch (err) {
    console.log(err);
  }
};
