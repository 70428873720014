<template>
  <div class="mileston-progress-interval-charts">
    <Bar
      id="my-chart-id"
      :chart-options="chartOptions"
      :chart-data="props.data.data"
      :chart-style="chartStyles"
      :inner-height="600"
      :plugins="chartPlugins"
    />
    <div class="mileston-progress-interval-charts__legends">
      <div class="mileston-progress-interval-charts__legend">
        <span class="mileston-progress-interval-charts__legend-icon"></span>
        Leveled Up
      </div>
      <div class="mileston-progress-interval-charts__legend">
        <span class="mileston-progress-interval-charts__legend-icon"></span>
        Maintained Level
      </div>
      <div class="mileston-progress-interval-charts__legend">
        <span class="mileston-progress-interval-charts__legend-icon"></span>
        Leveled Down
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { defineProps } from "vue";

import { Bar } from "vue-chartjs";
import { Chart } from "chart.js";
import { Title } from "chart.js";
import { Legend } from "chart.js";
import { Tooltip } from "chart.js";
import { BarElement } from "chart.js";
import { LinearScale } from "chart.js";
import { CategoryScale } from "chart.js";

import { drawFatArrowBar } from "./chartPlugin";
const props = defineProps<{
  data: Record<string, any>;
}>();
const chartStyles = ref<any>({ height: "530px" });
const chartPlugins = ref([drawFatArrowBar]);
const chartOptions = ref<any>({
  animation: false,
  responsive: true,
  tooltips: { enabled: false },
  hover: { mode: null },
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
      grace: 1,
      grid: {
        color: "#DCDDE2",
        borderDash: [5, 4],
        drawBorder: false,
      },
      gridLines: {
        drawBorder: false,
      },
    },
    x: {
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
});

Chart.register(Title, Legend, Tooltip, BarElement, LinearScale, CategoryScale);
</script>

<style lang="scss" scoped>
.mileston-progress-interval-charts {
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    p {
      color: $manatee;
    }
  }

  &__header-date {
    color: $manatee;
  }

  &__legends,
  &__legend {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &__legends {
    gap: 24px;
    margin-top: 24px;
  }

  &__legend {
    gap: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #272740;
  }

  &__legend-icon {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }

  &__legend:nth-child(1) span {
    background-color: #33798c;
  }
  &__legend:nth-child(2) span {
    background-color: #aaa246;
  }
  &__legend:nth-child(3) span {
    background-color: #b66e3f;
  }
}
</style>
