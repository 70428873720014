import { ActionTree } from "vuex";
import {
  EGenericCompanyActions,
  EGenericCompanyMutations,
  IProfileCompanyState,
} from "./company.types";

import router from "@/services/router";
import { HOME } from "@/services/router/router-names";
import { ROUTE_PROFILE } from "@/modules/profile/services/router/routes-names";

import { RootState } from "@/services/store/root-state";
import { getBaseName } from "@/services/store/utils/get-base-name";
import { userManager } from "@/modules/authentication/user-manager";
import { NotFoundProviderException } from "@/services/data/exceptions/not-found-provider.exception";
import { companyProvider } from "@/modules/profile/services/data/company/company.provider";
import { ICompany } from "@/modules/profile/services/data/company/company.types";
import isNull from "lodash/isNull";
import omitBy from "lodash/omitBy";

export const companyActions: ActionTree<IProfileCompanyState, RootState> = {
  /**
   * Fetch a company.
   *
   * @param param0
   * @param companyId Id of the company to be fetched
   */
  async [getBaseName(EGenericCompanyActions.FETCH)](
    { commit },
    companyId: number,
  ) {
    commit(EGenericCompanyMutations.SET_ERROR, null);
    commit(EGenericCompanyMutations.SET_LOADING, true);
    try {
      const data = await companyProvider.get(companyId);
      commit(EGenericCompanyMutations.SET_DATA, data);
    } catch (error) {
      commit(EGenericCompanyMutations.SET_ERROR, error);
      // Redirect the user if company profile does not exist
      if (error instanceof NotFoundProviderException) {
        const fallbackRoute = userManager.isLogged() ? ROUTE_PROFILE : HOME;
        if (router.currentRoute.value.name !== fallbackRoute) {
          router.replace({ name: fallbackRoute });
        }
      }
    } finally {
      commit(EGenericCompanyMutations.SET_LOADING, false);
    }
  },

  /**
   * Apply a partial update to the company information.
   * @important Should be used only on auth company module
   *
   * @param param0 vuex methods
   * @param partialData Updated data
   */
  async [getBaseName(EGenericCompanyActions.PATCH)](
    { commit, state },
    partialData: ICompany,
  ) {
    if (!state.data) {
      throw new Error("There is not company set");
    }

    const filteredData = omitBy(partialData, isNull) as Partial<ICompany>;

    // Remove the logo fields since this one will be updated using
    // a form data.
    delete filteredData.logo;

    commit(EGenericCompanyMutations.SET_LOADING, true);

    try {
      // Adding slash to the the company id as it is added to the end of the url
      // and django requires / at the end of a patch url
      const data = await companyProvider.patch(
        `${state.data.id}/`,
        filteredData,
      );
      commit(EGenericCompanyMutations.SET_DATA, { ...state.data, ...data });
      // commit(EGenericCompanyMutations.SET_DATA, {
      //   ...state.data,
      //   ...filteredData,
      // });
      // commit("profile/company/data", { ...state.data, ...filteredData });
    } catch (error) {
      commit(EGenericCompanyMutations.SET_ERROR, error);
      throw error;
    } finally {
      commit(EGenericCompanyMutations.SET_LOADING, false);
    }
  },

  /**
   * Reset company value stored.
   *
   * @param param0
   */
  async [getBaseName(EGenericCompanyActions.RESET)]({ commit }) {
    commit(EGenericCompanyMutations.SET_DATA, null);
  },
};
