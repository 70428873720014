<template>
  <div class="charts-dashboard">
    <div class="page-container">
      <header class="charts-dashboard__header">
        <div class="charts-dashboard__header-left">
          <h1>{{ $t("chartsDashboard.title") }}</h1>
          <nav
            v-if="showPagesNavigation"
            data-testid="charts-dashboard-pages-navigation"
          >
            <ul>
              <li
                v-for="(page, index) in pages"
                :key="index"
                :class="{
                  active: selectedPage === index,
                  disabled: loading,
                }"
                @click="selectedPage = index"
              >
                {{ page }}
              </li>
            </ul>
          </nav>
        </div>

        <div class="charts-dashboard__header-cta">
          <PxButton
            v-if="showRequestProcessCta && !isFreeUser"
            v-bind="requestProcessButtonProps"
            data-testid="request-process-cta"
            @click.stop="showProcessModal = true"
          />
          <RequestProcessesModal
            v-model:visibility="showProcessModal"
            show-overlay
            show-close
            title-path="chartsDashboard.requestProcessesModal.title[1]"
          />
        </div>

        <div
          v-if="isFreeUser || showMockDataCopy"
          class="charts-dashboard__note"
        >
          <PxIcon name="information--light-gray" size="16" />
          <i v-html="$t('chartsDashboard.mockData')"></i>
        </div>
      </header>

      <main>
        <!-- The overlay shown for free users -->
        <template v-if="isFreeUser">
          <div class="charts-dashboard__pro-feature-modal">
            <header>
              <PxIcon :size="60" name="private" />
              <h1>{{ proFeatureModalProps.title }}</h1>
              <p v-html="proFeatureModalProps.description"></p>
            </header>
            <footer>
              <PxButton
                :label="proFeatureModalProps.ctaLabel"
                type="blue"
                @click="goToSubscriptionPage"
              />
            </footer>
          </div>
          <div class="charts-dashboard__overlay"></div>
        </template>

        <!-- The navigation and filter bar in the dashboard -->
        <div class="charts-dashboard__theme-navigation">
          <PxNavigationTab
            v-show="showThemeNavigation"
            v-model:selected-tab="selectedNavbarId"
            :navigation-tabs="navBarItems"
            :show-number="false"
            :list-format="false"
            :class="{
              'charts-dashboard__theme-navigation--disabled':
                isFreeUser || loading,
            }"
          />
        </div>

        <template v-if="selectedTheme">
          <ListReportsView
            v-if="showListReportsView"
            :theme="selectedTheme"
            @loading="setLoading"
            @show-navigation="setThemeNavigationVisibility"
          />
          <MilestonProgressReportsView
            v-if="showMilestonProgressReportsView"
            :theme="selectedTheme"
            @loading="setLoading"
          />
          <ProcessReportsView
            v-else
            :theme="selectedTheme"
            @loading="setLoading"
            @show-mock-data="setMockDataCopyVisibility"
          />
        </template>
      </main>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import PxNavigationTab from "@/components/px-navigation-tab/px-navigation-tab.vue";
import ListReportsView from "@/modules/charts-dashboard/views/list-reports.view.vue";
import ProcessReportsView from "@/modules/charts-dashboard/views/process-reports.view.vue";
import RequestProcessesModal from "@/modules/charts-dashboard/components/common/request-processes-modal/request-processes-modal.vue";
import MilestonProgressReportsView from "@/modules/charts-dashboard/views/milestone-progress-reports.view.vue";

import { ROUTE_ACCOUNT_SETTINGS } from "@/modules/profile/services/router/routes-names";
import { EAccountSettingsNavigationTabs } from "@/modules/profile/components/account-settings/account-settings-navigation.vue";
import { EDashboardPages } from "@/modules/charts-dashboard/views/charts-dashboard.interface";
import { TranslateResult } from "vue-i18n";
import { EPxButtonType } from "@/components/px-button/px-button.types";

export interface IReportsViewThemes {
  title: TranslateResult;
  questionSlug: string;
  id: number;
  image_path: string;
}

export default defineComponent({
  name: "ChartsDashboardView",

  components: {
    PxNavigationTab,
    ListReportsView,
    ProcessReportsView,
    RequestProcessesModal,
    MilestonProgressReportsView,
  },

  data() {
    return {
      selectedPage: EDashboardPages.LIST_REPORTS,
      // selectedPage: EDashboardPages.MILESTONE_PROGRESS,
      selectedNavbarId: 1,
      loading: false,
      showThemeNavigation: true,
      showMockDataCopy: false,
      showProcessModal: false,
      showRequestProcessCta: false,
    };
  },

  computed: {
    isFreeUser(): boolean {
      // return false;
      return !this.$user.isProUser();
    },

    proFeatureModalProps(): any {
      return {
        title: this.$t("chartsDashboard.proFeatureModal.title"),
        description: this.$t("chartsDashboard.proFeatureModal.description"),
        ctaLabel: this.$t("chartsDashboard.proFeatureModal.cta"),
      };
    },

    pages() {
      return this.$tm("chartsDashboard.pages") as Array<string>;
    },

    showPagesNavigation(): boolean {
      return this.$features.isEnabled("processReports");
    },

    navBarItems(): Array<IReportsViewThemes> {
      return [
        {
          title: this.$t("chartsDashboard.navbarItems[0]"),
          id: 1,
          questionSlug: "Individual_Gender_Identity",
          image_path: "gender",
        },
        {
          title: this.$t("chartsDashboard.navbarItems[1]"),
          id: 2,
          questionSlug: "Individual_Race_Ethnicity",
          image_path: "ethnicity",
        },
        {
          title: this.$t("chartsDashboard.navbarItems[2]"),
          id: 3,
          questionSlug: "Individual_Sexual_Orientation",
          image_path: "sexual_orientation",
        },
      ];
    },

    selectedTheme(): IReportsViewThemes | null {
      const theme =
        this.navBarItems.find(
          (item: any) => item.id === this.selectedNavbarId,
        ) || null;

      return theme;
    },

    questionSlug(): string {
      return this.selectedTheme?.questionSlug || "";
    },

    showListReportsView(): boolean {
      return this.selectedPage === EDashboardPages.LIST_REPORTS;
    },

    showMilestonProgressReportsView(): boolean {
      return this.selectedPage === EDashboardPages.MILESTONE_PROGRESS;
    },

    requestProcessButtonProps(): any {
      return {
        type: EPxButtonType.GREEN,
        label: this.$t("chartsDashboard.requestProcessesModal.cta"),
      };
    },
  },

  watch: {
    selectedPage: {
      handler() {
        console.log("triggerd");
        if (this.selectedPage === EDashboardPages.MILESTONE_PROGRESS) {
          console.log("MILESTONE_PROGRESS");
          this.showProcessModal = false;
          this.showThemeNavigation = false;
          this.showRequestProcessCta = false;
        } else {
          this.showThemeNavigation = true;
        }
      },
    },
  },

  methods: {
    goToSubscriptionPage(): void {
      this.$router.push({
        name: ROUTE_ACCOUNT_SETTINGS,
        query: { tab: EAccountSettingsNavigationTabs.SUBSCRIPTION },
      });
    },

    setLoading(isLoading: boolean) {
      this.loading = isLoading;
    },

    setThemeNavigationVisibility(isVisible: boolean) {
      this.showThemeNavigation = isVisible;
    },

    setMockDataCopyVisibility(isVisible: boolean) {
      this.showMockDataCopy = isVisible;

      if (
        !this.isFreeUser &&
        !this.showListReportsView &&
        !isVisible &&
        this.selectedPage !== EDashboardPages.MILESTONE_PROGRESS
      ) {
        this.showRequestProcessCta = true;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.charts-dashboard {
  padding-top: 61px;

  @include breakpoint-down(lg) {
    max-width: 920px;
    margin: 0 auto;
  }

  @include breakpoint-down(md) {
    max-width: 800px;
  }

  @include breakpoint-down(sm) {
    padding: 58px 50px;
  }

  main {
    position: relative;
  }

  &__overlay {
    background-color: rgba(250, 250, 250, 0.8);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: z("floaters");
  }

  &__pro-feature-modal {
    position: fixed;
    left: 50%;
    top: 50vh;
    transform: translate(-50%, -50%);

    width: 500px;

    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 16px 0 rgba(39, 39, 64, 0.18);

    text-align: center;

    z-index: z("floaters") + 1;

    header {
      padding: 41px 64px 31px;
    }

    footer {
      position: relative;
      padding: 15px 0 17px 0;
    }

    footer::before {
      width: calc(100% - 64px);

      position: absolute;
      top: -1px;
      left: 50%;
      height: 1px;
      content: "";
      background-color: #f0f1f5;
      transform: translateX(-50%);
    }

    h1 {
      margin: 11px 0 16px 0;

      font-size: to-rem(20px);
      color: $ebony-clay;
      line-height: 1.5;
      letter-spacing: -0.2px;
    }

    p {
      line-height: 1.47;
      letter-spacing: -0.37px;
    }

    .px-button {
      position: relative;
      top: 1px;
      right: 1px;
      padding: 0 15px;
    }
  }

  &__header {
    display: flex;
    gap: 40px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    h1 {
      margin: 0 0 2px 0;

      font-size: 30px;
      line-height: 1.33;
      font-weight: 600;
      letter-spacing: -0.3px;
    }

    ul {
      display: flex;
      flex-flow: row;
      gap: 9px;
      padding: 0;
      margin: 0;
      list-style: none;
    }

    li {
      cursor: pointer;

      padding: 7px 12px 8px 11px;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.5;
      color: $manatee;
      border-radius: 4px;
    }

    li.active {
      color: $bluish;
      background-color: #2972ca1a;
    }

    li.disabled {
      pointer-events: none;
    }
  }

  :deep(.charts-locked-modal) {
    margin-top: 20vh;

    &.el-dialog {
      position: absolute;
      top: calc(50vh - 20vh);
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .el-dialog__header {
      padding: 41px 0 0;
    }

    .el-dialog__body {
      padding: 0 64px 31px;
    }

    .el-button {
      left: -1px;
    }

    .el-dialog__footer::before {
      width: calc(100% - 64px);
    }

    h1 {
      margin: 6px 0 16px 0;
    }

    p {
      color: $ebony-clay;
      line-height: 1.47;
      letter-spacing: -0.37px;
    }
  }

  &__header-left {
    display: flex;
    gap: 40px;
    align-items: center;
  }

  &__note {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;

    .px-icon {
      position: relative;
      top: -1px;
    }

    i {
      margin: 0;

      color: $manatee;
      font-size: to-rem(13px);
      line-height: 1.38;
      letter-spacing: -0.14px;
    }

    :deep(b) {
      @include grotesk(semiBold, italic);
    }
  }

  &__theme-navigation {
    position: absolute;
    top: 12px;
    display: flex;

    &--disabled {
      pointer-events: none;
    }

    @include breakpoint-down(lg) {
      position: relative;
      top: 0;
      margin-bottom: 30px;
    }
  }

  &__theme-navigation :deep(.px-navigation-tab) {
    gap: 25px;

    .px-navigation-tab-item__title {
      letter-spacing: -0.1px;
    }

    .px-navigation-tab-item__container {
      padding: 0 0 13px 0;
    }

    .px-navigation-tab-item--active {
      padding: 0 0 11px 0;
    }

    > div:nth-child(3) .px-navigation-tab-item__title {
      letter-spacing: -0.2px;
      margin-left: -1px;
    }

    .px-navigation-tab-item__border {
      height: 2px;
      border-radius: 0;
    }
  }

  &__header-cta {
    display: flex;
    align-self: flex-end;

    .px-button {
      padding: 0 14px;
    }
  }
}
</style>
