<template>
  <div class="mileston-levels-achieved-charts">
    <Bar
      id="my-chart-id"
      :chart-options="chartOptions"
      :chart-data="props.data.chartData.data"
      :chart-style="chartStyles"
      :inner-height="600"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { defineProps } from "vue";

import { Bar } from "vue-chartjs";
import { Chart } from "chart.js";
import { Title } from "chart.js";
import { Legend } from "chart.js";
import { Tooltip } from "chart.js";
import { BarElement } from "chart.js";
import { LinearScale } from "chart.js";
import { CategoryScale } from "chart.js";

const props = defineProps<{
  data: Record<string, any>;
}>();
const chartStyles = ref<any>({ height: "530px" });
const chartOptions = ref<any>({
  responsive: true,
  animation: false,
  maintainAspectRatio: false,
  tooltips: { enabled: false },
  hover: { mode: null },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      // beginAtZero: true,
      min: props.data.chartLimits.min,
      max: props.data.chartLimits.max,
      grace: 1,
      grid: {
        color: "#DCDDE2",
        borderDash: [5, 4],
        drawBorder: false,
      },
    },
  },
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        pointStyle: "circle",
        usePointStyle: true,
      },
    },
  },
});

Chart.register(Title, Legend, Tooltip, BarElement, LinearScale, CategoryScale);
</script>

<style lang="scss" scoped>
.mileston-progress-interval-charts {
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    p {
      color: $manatee;
    }
  }

  &__header-date {
    color: $manatee;
  }
}
</style>
