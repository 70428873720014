export let Network: NetworkManager;

export class NetworkManager {
  public isOnline: boolean = true;
  private callbacks: Record<string, (() => void)[]> = {
    onConnect: [],
    onDisconnect: [],
  };

  constructor() {
    this.isOnline = navigator.onLine;
  }

  public onDisconnect(callback: () => void) {
    this.callbacks.onDisconnect.push(callback);
    window.addEventListener("offline", () => {
      this.isOnline = false;
      callback();
    });

    if (!this.isOnline) {
      callback();
    }
  }

  public onConnect(callback: () => void) {
    this.callbacks.onConnect.push(callback);
    window.addEventListener("online", () => {
      this.isOnline = false;
      callback();
    });

    if (this.isOnline) {
      callback();
    }
  }

  public refreshStatus() {
    const newStatus = navigator.onLine;
    if (this.isOnline !== newStatus) {
      this.isOnline = newStatus;
      let callbacks = [];

      if (!this.isOnline) {
        callbacks = this.callbacks.onDisconnect;
      } else {
        callbacks = this.callbacks.onConnect;
      }

      for (const callback of callbacks) {
        callback();
      }
    }
  }

  static initialize() {
    if (!Network) {
      Network = new NetworkManager();
    }
  }
}
