<template>
  <div class="milestone-progress-reports">
    <header>
      <div class="milestone-progress-reports__select">
        <p>{{ $t(`chartsDashboard.select[0]`) }}</p>
        <ElSelect
          v-model="selectedListUid"
          filterable
          default-first-option
          popper-class="milestone-progress-reports__select-list"
          :disabled="listSelectDisabled"
          data-testid="milestone-progress-reports-select"
        >
          <ElOption
            v-for="list in lists"
            :key="list.uid"
            :value="list.uid"
            :label="list.title"
          />
        </ElSelect>
      </div>
    </header>

    <!-- No Lists Present -->
    <div v-if="lists?.length === 0" class="milestone-progress-reports__nolist">
      <div class="milestone-progress-reports__nolist__icon">
        <img src="img/no-matches.svg" />
      </div>
      <div class="milestone-progress-reports__nolist__text">
        <span>No lists available.</span>
        <span>
          You havent created any lists yet. Start by creating a new list to keep
          track of relevant companies
        </span>
      </div>
      <PxButton
        type="green"
        class="milestone-progress-reports__nolist__cta"
        @click="newListHandler"
      >
        <PxIcon :size="18" name="plus-white" />
        NEW LIST
      </PxButton>
    </div>
    <!-- No Lists Present -->

    <!-- No Companies In The Slected List -->
    <div
      v-if="selectedList && selectedList.companies.length === 0 && proUser"
      class="milestone-progress-reports__nocompanies"
    >
      <div class="milestone-progress-reports__nocompanies__icon">
        <img src="img/no-matches.svg" />
      </div>
      <div class="milestone-progress-reports__nocompanies__text">
        <span>No companies in the list.</span>
        <span>
          Start adding companies to track your network’s milestone progress.
        </span>
      </div>
      <PxButton
        type="green"
        class="milestone-progress-reports__nocompanies__cta"
        @click="openListHandler"
      >
        OPEN LIST
      </PxButton>
    </div>
    <!-- No Companies In The Slected List -->

    <!-- Milestone Data -->
    <div
      v-if="(selectedList && selectedList.companies.length > 0) || !proUser"
      v-loading="loading"
      element-loading-background="#fafafa"
      class="mileston-progress-reports__content"
      :class="{
        'mileston-progress-reports__loading': loading,
      }"
    >
      <template v-if="!loading">
        <div class="milestone-progress-reports__wrapper">
          <div class="milestone-progress-reports__header">
            <PxNavigationTab
              :navigation-tabs="navigationTabItems"
              :selected-tab="selectedTab"
              @update:selected-tab="handleTabSelection"
            />
          </div>

          <div class="milestone-progress-reports__content">
            <div class="milestone-progress-reports__title">
              <div
                v-if="selectedTab === 'levels-date-range'"
                class="milestone-progress-reports__title-primary"
              >
                <h1>
                  {{ selectedList?.title }} company levels at start vs at end
                </h1>
                <p>
                  Level progress represents growth across the eight VIL
                  categories.
                </p>
              </div>
              <div
                v-if="selectedTab === 'levels-milestone'"
                class="milestone-progress-reports__title-primary"
              >
                <h1>
                  {{ selectedList.title }} company levels & milestones achieved
                </h1>
                <p>
                  Level progress represents growth across the eight VIL
                  categories.
                </p>
              </div>
              <div class="milestone-progress-reports__title-secondary">
                <h1>
                  {{ assessmentData?.assessmentDates?.start }} -
                  {{ assessmentData?.assessmentDates?.end }}
                </h1>
              </div>
            </div>
            <div class="milestone-progress-reports__charts">
              <template v-if="levelsChartData">
                <MilestoneProgressIntervalChartVue
                  v-if="selectedTab === navigationTabItems[0].id"
                  :data="levelsChartData"
                />
              </template>
              <template v-if="milestonesChartData">
                <MilestoneLevelsAchievedChartVue
                  v-if="selectedTab === navigationTabItems[1].id"
                  :data="milestonesChartData"
                />
              </template>
            </div>
            <div
              v-if="progressTableData"
              class="milestone-progress-reports__tables"
            >
              <PxTable :data="progressTableData" />
            </div>
          </div>

          <div class="milestone-progress-reports__data">
            <div class="milestone-progress-reports__data__inner">
              <div
                v-if="highlightsData && highlightsData.length"
                class="milestone-progress-reports__data__highlights"
              >
                <div
                  v-for="(data, index) in highlightsData"
                  :key="index"
                  class="milestone-progress-reports__highlights__section"
                >
                  <span
                    class="milestone-progress-reports__highlights__title"
                    v-html="`${data.icon} ${data.title}`"
                  ></span>
                  <div
                    v-for="(item, itemIndex) in data.items"
                    :key="itemIndex"
                    class="milestone-progress-reports__highlights__sub-item"
                  >
                    <span>{{ item.title }}</span>
                    <span>{{ item.subtitle }}</span>
                  </div>
                </div>
              </div>

              <div class="milestone-progress-reports__data__assesment">
                <div
                  class="milestone-progress-reports__data__assesment__header"
                >
                  Assesment Data
                </div>
                <div
                  v-if="assessmentData"
                  class="milestone-progress-reports__data__assesment__table"
                  assessmentData
                >
                  <PxTable :data="assessmentData" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <template v-if="showEmptyState">
          <RequestProcessesModal v-model:visibility="showEmptyState" />
          <div class="process-reports__overlay"></div>
        </template>
      </template>
    </div>
    <!-- Milestone Data -->
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { watch } from "vue";
import { computed } from "vue";
import { onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import PxTable from "@/components/px-table/px-table.vue";
import PxButton from "@/components/px-button/px-button.vue";
import PxNavigationTab from "@/components/px-navigation-tab/px-navigation-tab.vue";
import MilestoneLevelsAchievedChartVue from "@/modules/charts-dashboard/components/milestone-progress-levels-chart/milestone-progress-levels-chart.vue";
import MilestoneProgressIntervalChartVue from "@/modules/charts-dashboard/components/milestone-progress-interval-chart/milestone-progress-interval-chart.vue";

import { userManager } from "@/modules/authentication/user-manager";
import { CompanyListState } from "@/modules/company-lists/services/store/company-list/company-list.modules";
import { createProgressTable } from "@/modules/charts-dashboard/utils/milestone-reports.table";
import { createHiglightsData } from "@/modules/charts-dashboard/utils/milestone-reports.table";
import { MilestoneReportsState } from "@/modules/charts-dashboard/services/store/milestone/milestone.module";
import { createLevelsChartData } from "@/modules/charts-dashboard/utils/milestone-reports.chart";
import { createMilestonesChartData } from "@/modules/charts-dashboard/utils/milestone-reports.chart";
import { createAssessmentTableData } from "@/modules/charts-dashboard/utils/milestone-reports.table";

import { defaultHighlightData } from "@/modules/charts-dashboard/utils/milestone-reports.table";
import { defaultLevelsChartData } from "@/modules/charts-dashboard/utils/milestone-reports.chart";
import { defaultProgressTableData } from "@/modules/charts-dashboard/utils/milestone-reports.table";
import { defaultAssesmentTableData } from "@/modules/charts-dashboard/utils/milestone-reports.table";
import { defaultMilestonesChartData } from "@/modules/charts-dashboard/utils/milestone-reports.chart";

import type { INavigationTab } from "@/components/px-navigation-tab/px-navigation-tab.vue";
import type { IMilestoneReports } from "@/modules/charts-dashboard/services/data/milestone-reports/milestone.interface";

const store = useStore();
const router = useRouter();

const proUser = computed(userManager.isProUser);
const lists = computed(() => store.get(CompanyListState.Getter.VALUES));
const milestoneReports = computed<IMilestoneReports>(() =>
  store.get(MilestoneReportsState.Getter.VALUE),
);

const loading = ref(false);
const selectedList = ref();
const showEmptyState = ref(false);
const selectedListUid = ref();
const listSelectDisabled = ref(false);

const highlightsData = ref<any>();
const assessmentData = ref<any>();
const levelsChartData = ref<any>();
const progressTableData = ref<any>();
const milestonesChartData = ref<any>();

const navigationTabItems = ref([
  {
    id: "levels-date-range",
    title: "Levels at Start vs End",
  },
  {
    id: "levels-milestone",
    title: "Levels & Milestones Achieved",
  },
] as INavigationTab[]);
const selectedTab = ref(navigationTabItems.value[0].id);

function handleTabSelection(val: any) {
  selectedTab.value = val;
}

function newListHandler() {
  router.push({ path: "/lists", query: { form_open: "true" } });
}

function openListHandler() {
  router.push({ path: "/lists/hwjwgs11" });
}

watch(milestoneReports, () => {
  if (milestoneReports.value.companies?.length > 0) {
    progressTableData.value = createProgressTable(
      milestoneReports.value,
      selectedList.value?.title,
    );
    highlightsData.value = createHiglightsData(milestoneReports.value);
    assessmentData.value = createAssessmentTableData(milestoneReports.value);
    levelsChartData.value = createLevelsChartData(milestoneReports.value);
    milestonesChartData.value = createMilestonesChartData(
      milestoneReports.value,
    );
  }
});

watch(lists, () => {
  if (lists.value.length > 0) {
    selectedListUid.value = lists.value[0].uid;
  }
});

watch(selectedListUid, () => {
  if (selectedListUid.value && proUser) {
    const list = lists.value.find(
      (item: any) => item.uid === selectedListUid.value,
    );

    selectedList.value = list;
    if (proUser.value) {
      store.dispatch(MilestoneReportsState.Action.GET_VALUE, selectedListUid);
    }
  }
});

onMounted(() => {
  store.dispatch(CompanyListState.Action.GET_VALUES);

  if (!proUser.value) {
    progressTableData.value = defaultProgressTableData;
    highlightsData.value = defaultHighlightData;
    assessmentData.value = defaultAssesmentTableData;
    levelsChartData.value = defaultLevelsChartData;
    milestonesChartData.value = defaultMilestonesChartData;
  }
});
</script>

<style lang="scss" scoped>
.debug {
  background-color: rgba(82, 195, 149, 0.14);
}
.debug2 {
  background-color: rgba(195, 82, 82, 0.14);
}

.milestone-progress-reports {
  header {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-bottom: 40px;

    @include breakpoint-down(lg) {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
  }

  // No Lists Available
  &__nolist,
  &__nocompanies {
    background-color: #fff;
    border: 1px solid #e4e6ea;
    width: 100%;
    min-height: calc(100vh - 445px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }

  &__nolist__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__nolist__text span:first-child {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    color: #272740;
  }

  &__nolist__text span:last-child {
    font-size: 15px;
    font-weight: 500;
    line-height: 25px;
    color: #adb0bd;
  }

  &__nolist__cta img {
    margin-right: 8px;
  }

  // No Companies In The List

  // Content
  &__wrapper {
    // background-color: transparent;
    // border: 1px solid #e4e6ea;
  }

  &__header {
    ::v-deep .px-navigation-tab {
      background-image: linear-gradient(180deg, #fff, #fafafa);
      justify-content: flex-start;
      padding-top: 32px;
      padding-left: 32px;
      min-width: 100%;
    }
    ::v-deep .px-navigation-tab-item__container {
      padding: 0 20px 12px 20px;
    }
  }

  &__content {
    padding: 32px;
    background-color: #fff;
    border: 1px solid #e4e6ea;
  }

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    p {
      color: $manatee;
    }
    &-secondary {
      color: $manatee;
    }
  }

  &__charts {
    padding-bottom: 32px;
  }

  &__select {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    p {
      min-width: 65px;
    }
  }

  &__data {
    padding: 0 32px;
  }

  &__data__inner {
    background-color: transparent;
    border: 1px solid #e4e6ea;
    border-top: none;
    padding: 0 32px 32px 32px;
  }

  &__data__highlights {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    padding: 48px 0;
  }

  &__highlights__section {
    padding: 0 60px;
  }

  &__highlights__section:not(:last-child) {
    border-right: 1px solid;
    border-image: linear-gradient(
        to bottom,
        #e4e6ea35 0%,
        #e4e6ea 40%,
        #e4e6ea 60%,
        #e4e6ea35 100%
      )
      1;
  }

  &__highlights__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    padding-bottom: 4px;
    color: #272740;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__highlights__sub-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 12px;
  }

  &__highlights__sub-item span:first-child {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #272740;
  }

  &__highlights__sub-item span:nth-child(2) {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #272740;
  }

  &__data__assesment {
    border: 1px solid #e4e6ea;
    background-color: #fff;
    // background-color: green;
    box-shadow: 0 2px 11px 0 #0000000d;
  }

  &__data__assesment__header {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    color: #272740;
    padding: 12px 20px;
    background: linear-gradient(to bottom, #fff 0%, #f4f4f4 100%);
  }

  &__data__assesment__table {
    padding: 0 20px;
  }
}
</style>
