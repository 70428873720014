import { IMatchingResponse } from "@/services/data/matching-responses/matching-response.interface";

// TODO: Rename this enumerable since it's being solely used for icon purposes.
export enum EMilestoneStatusType {
  COMPLETED = "milestone-completed",
  IN_PROGRESS = "milestone-in-progress",
  PLANNED = "milestone-planned",
  TO_BE_PLANNED = "milestone-to-be-planned",
  CRITICAL = "critical-milestone",
  SELF_ASSESSED = "self-assessed",
  EVIDENCE_PROVIDED = "evidence-provided",
}

export const MILESTONE_STATES = Object.freeze({
  COMPLETED: "completed" as const,
  IN_PROGRESS: "in-progress" as const,
  PLANNED: "planned" as const,
  TO_BE_PLANNED: "to-be-planned" as const,
});

export type TMilestoneState =
  (typeof MILESTONE_STATES)[keyof typeof MILESTONE_STATES];

export const MILESTONE_DETAILS_TABS = Object.freeze({
  PLAN: "plan" as const,
  COMPLETE: "complete" as const,
  LEVELS: "levels-date-range" as const,
  MILESTONES: "levels-milestone" as const,
});

export type TMilestoneDetailsTab =
  (typeof MILESTONE_DETAILS_TABS)[keyof typeof MILESTONE_DETAILS_TABS];

export type TMilestoneCategoryValueType = "SINGLE" | "RANGE";

export interface IMilestone {
  category_level: IMilestoneCategoryLevel;
  completed: boolean;
  created_at: string;
  critical: boolean;
  date_of_completion: string | null;
  evidence_provided: boolean;
  evidence_published: boolean;
  evidence: IMatchingResponse[];
  finances_needed: number | null;
  in_progress: boolean;
  outcomes: string;
  plan_published: boolean;
  planned: boolean;
  resources: string;
  state: TMilestoneState;
  strategy: string;
  target_date: string | null;
  uid: string;
  updated_at: string;
  user_profile: string;
}

export interface IMilestoneCategoryLevel {
  achieved_milestones_description: string;
  achieved_milestones_title: string;
  achievements: string;
  category: IMilestoneCategory;
  created_at: string;
  description: string;
  id: number;
  level: number;
  next_milestones_description: string;
  next_milestones_title: string;
  requirements: string;
  updated_at: string;
}

export interface IMilestoneCategory {
  abbreviation: string;
  color: string;
  created_at: string;
  description: string;
  group: string;
  id: number;
  name: string;
  order: number;
  requirements_title: string;
  updated_at: string;
  value_type: TMilestoneCategoryValueType;
}

export interface IMilestoneEvidence {
  answers: string[];
  question: number;
  value: string;
}

export interface ICreateMilestonePayload {
  category_level: number;
  critical: boolean;
  strategy: string;
  outcomes: string;
  resources: string;
  finances_needed: number | null;
  target_date: number | null;
  plan_published: boolean;
  date_of_completion: string | null;
  evidence_published: boolean;
  evidence: Array<IMatchingResponse>;
}

export interface IMilestoneForm {
  category_level: number;
  plan: {
    critical: boolean;
    strategy: string;
    outcomes: string;
    resources: string;
    financesNeeded: string | null;
    targetDate: string | null;
  };
  evidence: {
    dateOfCompletion: Date | null;
    evidence: IMilestoneEvidence[];
  };
}

export type TUpdateMilestonePayload = Omit<
  ICreateMilestonePayload,
  "category_level"
>;
